<template>
  <v-main>
    <v-container>
      <v-row class="justify-center mt-4">
        <v-col cols="auto">
          <img
            :src="$store.state.userSettings.darkMode ? paradiseInverseLogo : paradiseLogo"
            height="50"
            alt="Paradise Logo"
          />
        </v-col>
        <v-col cols="auto">
          <img
            :src="$store.state.userSettings.darkMode ? clientInverseLogo : clientLogo"
            height="55"
            alt="Cordis Integralis Logo"
          />
        </v-col>
      </v-row>
      <home-buttons></home-buttons>
    </v-container>
  </v-main>
</template>

<script>
export default {
  components: {
    homeButtons: () => import("@/layouts/intranet-empty/components/HomeBtns"),
  },
  data: () => ({
    clientLogo: require("../assets/images/client/logo.svg"),
    clientInverseLogo: require("../assets/images/client/logo_inverse.svg"),
    paradiseLogo: require("../assets/images/paradise_logo.svg"),
    paradiseInverseLogo: require("../assets/images/paradise_logo_inverse.svg"),
  }),
};
</script>

<style scoped></style>
